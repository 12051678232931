<template>
  <div class="wrapper">
    <transition name="fade">
      <div v-if="NextSubject && !next.attendance_set" class="notification_wrapper">
        <section class="subject_notification mb-4" :class="{ finished: next.attendance_set }">
          <h3 v-if="startedNext" class="mb-0">{{ $t('lesson_already_begun') }}!</h3>
          <h3 v-else class="mb-0">{{ $t('next_lesson') }}!</h3>
          <div v-if="next.attendance_set" class="standart_block d-flex align-items-center mt-0 ml-3">
            <compSvg/>
            <span class="mb-0">{{$t('completed')}}</span>
          </div>
          <div class="subject_info">
            <LetterIcon v-if="next.material" :obj="next.material" :title="next.material ? next.material.title : 'В расписании'"
                        class="md student-icon mr-2"/>
            <div v-else class="mr-4">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="64" height="64" rx="32" fill="#45B870"/>
                <g clip-path="url(#clip0)">
                  <path d="M41 23H40.0001V20.9999C40.0001 20.4481 39.552 20 38.9999 20H38C37.4479 20 37.0001 20.4481 37.0001 20.9999V23H26.9999V20.9999C26.9999 20.4481 26.5521 20 26 20H25.0001C24.448 20 23.9999 20.4481 23.9999 20.9999V23H23C21.346 23 20 24.346 20 26V41C20 42.654 21.346 44 23 44H41C42.654 44 44 42.654 44 41V26C44 24.346 42.654 23 41 23ZM41.9999 41C41.9999 41.551 41.551 41.9999 41 41.9999H23C22.449 41.9999 22.0001 41.551 22.0001 41V30.04H41.9999V41Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(20 20)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3 class="mb-0">{{next.material ? next.material.title : 'В расписании'}}</h3>
          </div>
          <button v-if="next.material"
                  class="btn-themed"
                  @click="openLesson">{{ $t('enter_class') }}
          </button>
        </section>
      </div>
    </transition>
    <div class="home_wrapper">
      <section class="home">
        <div class="home__home_header">
          <h1 id="page-title" class="mb-0">{{ $t('hello') }}, {{ $store.state.user ? $store.state.user.name :
              '' }} 👋</h1>
          <div class="home__home_header-teacher">
            <div class="name">
              <h3 class="mb-0">{{ $t('teacher') }}</h3>
              <span></span>
              <h4 class="mb-0">{{this.teacher ? this.teacher.name : 'No name'}}</h4>
            </div>
            <LetterIcon :obj="this.teacher" :title="this.teacher ? this.teacher.name : 'No name'" class="md student-icon ml-2"/>
          </div>
        </div>
      </section>
      <div class="row">
        <b-col class="mt-md-0 mt-3">
          <div class="tabs-navigator lg d-inline-block">
            <a :class="{ active: subjects === 0 }" @click="subjects = 0">{{ $t('coming_lessons') }}</a>
            <a :class="{ active: subjects === 1 }" @click="subjects = 1">{{ $t('completed_lessons') }}</a>
            <!--            <a :class="{ active: subjects === 2 }" @click="subjects = 2">{{ $t('selfstudy') }}</a>-->
          </div>
          <div class="spacecard min-h-card pb-3">
            <div v-if="subjects === 0">
              <div v-if="NextSubject || schedule.length">
                <div v-if="NextSubject && next" class="lessons">
                  <div class="spacecard__card started_card" :class="{ finished: next.attendance_set }">
                    <div class="subject_data">
                      <LetterIcon v-if="next.material"
                                  :obj="next.material"
                                  :title="next.material ? next.material.title : 'В расписании'"
                                  class="md student-icon mr-3"/>
                      <div v-else class="mr-3">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="64" height="64" rx="32" fill="#45B870"/>
                          <g clip-path="url(#clip0)">
                            <path d="M41 23H40.0001V20.9999C40.0001 20.4481 39.552 20 38.9999 20H38C37.4479 20 37.0001 20.4481 37.0001 20.9999V23H26.9999V20.9999C26.9999 20.4481 26.5521 20 26 20H25.0001C24.448 20 23.9999 20.4481 23.9999 20.9999V23H23C21.346 23 20 24.346 20 26V41C20 42.654 21.346 44 23 44H41C42.654 44 44 42.654 44 41V26C44 24.346 42.654 23 41 23ZM41.9999 41C41.9999 41.551 41.551 41.9999 41 41.9999H23C22.449 41.9999 22.0001 41.551 22.0001 41V30.04H41.9999V41Z" fill="white"/>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="24" height="24" fill="white" transform="translate(20 20)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="subject_text">
                        <h3 class="mb-0">{{ next.material ? next.material.title : 'В расписании' }}</h3>
                        <div v-if="next.attendance_set" class="standart_block d-flex align-items-center mt-1">
                          <compSvg/>
                          <span class="mb-0">{{$t('completed')}}</span>
                        </div>
                        <p v-if="startedNext && !next.attendance_set" class="started mb-0">{{ $t('lesson_already_begun') }}</p>
                        <p v-if="!startedNext" class="started mb-0">{{ $t('next_lesson') }} - {{ next.start | moment('HH:mm') }}</p>
                      </div>
                    </div>
                    <div class="control" v-if="!next.attendance_set">
                      <button v-if="next.material" class="btn-themed" @click="openLesson">{{ $t('enter_class') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="schedule" class="lesson_list">
                  <template v-for="(lesson, lind) in schedule">
                    <div @click="selectedCard(lesson)"
                         :class="{active_card: selectedItem === lesson.id}"
                         class="spacecard__card standart_card"
                         v-if="NextSubject ? lesson.id !== next.id : true"
                         :key="'sched-'+lind">
                      <div class="subject_data">
                        <LetterIcon v-if="lesson.material"
                                    :obj="lesson.material"
                                    :title="lesson.material ? lesson.material.title : 'В расписании'"
                                    class="md student-icon mr-3"/>
                        <div v-else class="mr-3">
                          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="32" fill="#45B870"/>
                            <g clip-path="url(#clip0)">
                              <path d="M41 23H40.0001V20.9999C40.0001 20.4481 39.552 20 38.9999 20H38C37.4479 20 37.0001 20.4481 37.0001 20.9999V23H26.9999V20.9999C26.9999 20.4481 26.5521 20 26 20H25.0001C24.448 20 23.9999 20.4481 23.9999 20.9999V23H23C21.346 23 20 24.346 20 26V41C20 42.654 21.346 44 23 44H41C42.654 44 44 42.654 44 41V26C44 24.346 42.654 23 41 23ZM41.9999 41C41.9999 41.551 41.551 41.9999 41 41.9999H23C22.449 41.9999 22.0001 41.551 22.0001 41V30.04H41.9999V41Z" fill="white"/>
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="24" height="24" fill="white" transform="translate(20 20)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="subject_text">
                          <h3 class="mb-1">{{lesson.material ? lesson.material.title : 'В расписании'}}</h3>
                          <span v-if="lessonWasAttended(lesson)" class="text-success">{{ $t('attended') }}</span>
                          <div v-if="lesson.attendance_set" class="standart_block d-flex align-items-center mt-0">
                            <compSvg/>
                            <span class="mb-0">{{$t('completed')}}</span>
                          </div>
                          <p v-if="Date.now() > lesson.datetime" class="started mb-0">
                            {{ $t('started_lesson') }}
                          </p>
                          <p class="standart mb-0">{{ lesson.start | moment('HH:mm') }}, {{
                              lesson.start | moment('ddd') }}, {{ lesson.datetime.toUpperCase() |
                                moment('D MMM')}}</p>
                        </div>
                      </div>
                      <div class="control">
                        <div class="subject_time">
                          <clockSvg/>
                          <span>{{ lesson.duration }} {{ $t('min') }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div v-else>{{$t('no_program')}}</div>
            </div>
            <div v-if="subjects === 1">
              <div v-if="previous || last.length > 0">
                <template v-for="p in previous">
                  <div v-if="p && p.pivot.homework_done === 0"
                       :key="`previous-${p.id}`"
                       :data-id="p.id"
                       class="spacecard__card homework_card">
                    <div class="subject_data">
                      <LetterIcon :obj="p.material"
                                  v-if="p.material"
                                  :title="p.material.title"
                                  class="md student-icon mr-3"/>
                      <div class="subject_text">
                        <h3 class="mb-0" v-if="p.material">{{p.material.title}}</h3>
                        <p class="standart mb-0">{{ p.start | moment('HH:mm') }}, {{
                            p.start | moment('ddd') }}, {{ p.datetime.toUpperCase() |
                              moment('D MMM')}}</p>
                        <div class="homework_block d-flex align-items-center">
                          <homeSvg/>
                          <span class="mb-0">{{$t('homework')}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="control">
                      <button class="btn-themed btn-white" @click="doHomework(p)">
                        {{$t('homework_do_homework')}}
                      </button>
                    </div>
                  </div>
                </template>

                <div v-if="last.length">
                  <div @click="selectedCardOld(lesson)"
                       :class="{active_card: selectedItem === lesson.id}"
                       class="spacecard__card standart_card"
                       v-for="(lesson, index) in last"
                       :key="'less' + index">
                    <div class="subject_data">
                      <LetterIcon :obj="lesson.material" v-if="lesson.material" :title="lesson.material.title"
                                  class="md student-icon mr-3"/>
                      <div class="subject_text">
                        <h3 class="mb-0" v-if="lesson.material">{{lesson.material.title}}</h3>
                        <p class="standart mb-0">{{ lesson.start | moment('HH:mm') }}, {{
                            lesson.start | moment('ddd') }}, {{ lesson.datetime.toUpperCase() |
                              moment('D MMM')}}</p>

                        <div class="standart_block d-flex align-items-center">
                          <compSvg/>
                          <span class="mb-0">{{$t('homework_done')}}</span>
                          <span v-if="lesson.pivot.homework_done == 1" class="text-success ml-3">
                            <correctSvg/>
                            {{ $t('homework') }}
                          </span>
                          <span v-if="lesson.pivot.homework_reviewed == 1" class="text-primary ml-3">
                            <correctTSvg class="svg-blue"/>
                            {{ $t('reviewed') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="control">
                      <button class="btn-themed btn-white repeat"
                              @click="preview(lesson.material, lesson, true)">{{$t('repeat')}}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div v-else>
                <h3>{{$t('nothing_found')}}</h3>
              </div>

              <div v-if="hasMore" class="text-center mt-3">
                <a class="btn btn-themed btn-themed-squared btn-themed-outline btn-sm px-5"
                   @click="loadMore">
                  {{ $t('more') }}
                </a>
              </div>

            </div>



            <!--            <div v-if="subjects === 2">-->
            <!--              <div v-for="(s, sind) in selfstudy" :key="`self-${sind}`" class="spacecard__card standart_card d-block">-->
            <!--                <div class="row align-items-center">-->
            <!--                  <div class="col-auto">-->
            <!--                    <LetterIcon :obj="s.material"-->
            <!--                                v-if="s.material"-->
            <!--                                :title="s.material.title"-->
            <!--                                class="md student-icon mr-3"/>-->
            <!--                  </div>-->
            <!--                  <div class="col">-->
            <!--                    <div class="subject_text">-->
            <!--                      <h3 class="mb-0" v-if="s.material">{{s.material.title}}</h3>-->
            <!--                    </div>-->
            <!--                    <div class="mt-2" v-if="s.finished || s.reviewed">-->
            <!--                      <span v-if="s.finished" class="done-span mr-2"><compSvg class="mr-1"/>{{ $t('done') }}</span>-->
            <!--                      <span v-if="s.reviewed" class="reviewed-span"><correctSvg class="mr-1"/>{{ $t('reviewed') }}</span>-->
            <!--                    </div>-->
            <!--                    <div v-else>-->
            <!--                      <span class="closed-span mt-2" v-if="!s.availableForStudy"><LockSvg class="mr-2"/>{{ $t('lesson_closed') }}</span>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  &lt;!&ndash;                  <div class="col-3">&ndash;&gt;-->
            <!--                  &lt;!&ndash;                    <div v-if="s.finished" class="text-info"><correctSvg class="mr-2"/>{{ $t('done') }}</div>&ndash;&gt;-->
            <!--                  &lt;!&ndash;                    <div v-if="s.reviewed" class="text-success"><correctSvg class="mr-2"/>{{ $t('reviewed') }}</div>&ndash;&gt;-->
            <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--                  <div class="col-auto">-->
            <!--                    <button class="btn-themed btn-white repeat"-->
            <!--                            :disabled="!s.availableForStudy"-->
            <!--                            @click="selfStudy(s.material)">-->
            <!--                      {{ s.finished ? $t('view') : $t('start') }}-->
            <!--                    </button>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </b-col>
      </div>
      <LinkToSettings/>
    </div>
    <PresentationPreviewModal v-if="showmodal"
                              :material="material"
                              :lesson="lessonForHomework"
                              :homework="homework"/>
  </div>
</template>

<script>
import clockSvg from '@/assets/svg/clock.svg'
// import LockSvg from "@/assets/svg/lock.svg"
import homeSvg from '@/assets/svg/homework.svg'
import compSvg from '@/assets/svg/completed.svg'
import correctSvg from '@/assets/svg/completed_green.svg'
import correctTSvg from '@/assets/svg/completed_t.svg'
import {StudentPageService} from "@/services/api.service"
import LetterIcon from '@/views/parts/general/LetterIcon'
import EventBus from "@/services/event-bus"
import moment from 'moment-timezone'
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'
import LinkToSettings from "../../parts/student/LinkToSettings"

export default {
  name: "StudentHomeNew",
  components: {
    LinkToSettings,
    clockSvg,
    homeSvg,
    compSvg,
    correctSvg,
    correctTSvg,
    // LockSvg,
    LetterIcon,
    PresentationPreviewModal
  },
  data() {
    return {
      subjects: 0,
      loaded: false,
      teacher: null,
      next: null,
      previous: [],
      last: [],
      nextStatus: false,
      schedule: [],
      selfstudy: [],
      showmodal: false,
      selectedItem: null,
      material: null,
      homework: true,
      lessonForHomework: null,
      page: 1,
      hasMore: false,
      perPage: 8
    }
  },
  metaInfo() {
    return {
      title: this.$t('main'),
    }
  },
  mounted() {
    EventBus.$emit('breadcrumbs', [])
    StudentPageService.home(this.page).then(res => {
      let d = res.data.data
      this.teacher = d.teacher
      this.next = d.next
      this.previous = this.previous.concat(d.previous)
      this.last = this.last.concat(d.last)
      this.hasMore = (d.previous.length === this.perPage || d.last.length === this.perPage)
      this.schedule = d.schedule
      this.selfstudy = d.selfstudy.data
      this.loaded = true
    });
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === "presentationpreviewmodal") {
        this.showmodal = false
      }
    });
    this.startedNext
    setTimeout(() => {
      this.NextSubject
    }, 500)
  },
  computed: {
    now() {
      return moment().tz(this.user.timezone).format('ddd DD')
    },
    user() {
      return this.$store.state.user
    },
    NextSubject() {
      if (!this.next) {
        return false
      }
      // return false;
      let now = Date.now();
      let result;
      let start = this.next.start
      if (moment(now).tz(this.user.timezone).format("YYYY-MM-DD HH:mm:ssz") >= moment(start).tz(this.user.timezone).subtract(120, "minutes").format("YYYY-MM-DD HH:mm:ssz")) {
        result = true
      }
      return result
    },
    startedNext() {
      if (!this.next) {
        return false
      }
      let now = Date.now();
      let result;
      let start = this.next.start
      if (moment(now).tz(this.user.timezone).format("YYYY-MM-DD HH:mm:ssz") >= start) {
        result = true
      }
      return result
    },
  },
  methods: {
    selectedCard(lesson) {
      this.selectedItem = lesson.id
      this.$router.push({name: 'Lesson', params: {id: lesson.id, code: lesson.code}})
    },
    selectedCardOld(lesson) {
      this.$router.push({
        name: 'Lesson',
        params: {id: lesson.id, code: lesson.code, homework: 'homework'}
      })
    },
    doHomework(previous) {
      this.$router.push({
        name: 'Lesson',
        params: {id: previous.id, code: previous.code, homework: 'homework'}
      })
    },
    openLesson() {
      this.$router.push({name: 'Lesson', params: {id: this.next.id, code: this.next.code}})
    },
    preview(material, lesson, homework = true) {
      if (!material) {
        return
      }
      this.material = material
      this.homework = homework
      this.lessonForHomework = lesson
      this.showmodal = true
      this.$nextTick(() => {
        this.$bvModal.show('presentationpreviewmodal')
      })
    },
    lessonWasAttended(lesson) {
      if(!lesson || !lesson.attendance_set) return false
      return lesson.pivot.attended === 1
    },
    // selfStudy(material) {
    //   console.log(material)
    //   this.$router.push({
    //     name: 'SelfStudyLesson',
    //     params: {id: material.id}
    //   })
    // }
    async loadMore() {
      this.page++
      const res = await StudentPageService.home(this.page)
      let d = res.data.data
      this.hasMore = (d.previous.length === this.perPage || d.last.length === this.perPage)
      this.previous = this.previous.concat(d.previous)
      this.last = this.last.concat(d.last)
    }
  }
}
</script>

<style lang="scss" scoped>

.active_card {
  background: #F8F9FB;
}

/* Notification Styles */
.notification_wrapper {
  position: relative;
  width: 100%;
}

.homework_block {
  margin-top: 8px;

  span {
    font-weight: 600;
    font-size: 14px;
    color: #FFBD0E;
    margin-left: 8px;
  }
}

.standart_card {
  cursor: pointer;
  &:hover {
    background: #F8F9FB;
  }
}

.standart_block {
  margin-top: 8px;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #9B9FA9;
    margin-left: 8px;
  }
}

.subject_notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 99.8vw;
  height: 80px;
  margin-top: -30px;
  background: #E6F7FF;
  margin-left: calc(-50vw + 50% - 8px);

  &.finished {
    background: #f3f3f3;
    border-bottom: 1px solid #dedede;
  }

  .subject_info {
    display: flex;
    align-items: center;
    padding: 0 32px;

    h3 {
      font-size: 16px;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 12px;
    }
  }
}

/* General */

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home_wrapper {
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

button.repeat {
  width: 140px;
}

/* Home Styles */

.home {
  &__home_header {
    display: flex;
    padding-bottom: 32px;
    align-items: center;
    justify-content: space-between;

    &-teacher {
      display: flex;
      align-items: center;

      .name {
        display: flex;
        align-items: center;

        h3 {
          font-size: 16px;
          font-weight: 600;
        }

        span {
          width: 3px;
          height: 3px;
          background: #000;
          border-radius: 100px;
          margin: 0 6px;
        }

        h4 {
          max-width: 230px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 600;
          font-size: 16px;
          color: #43B4EB;
          margin-right: 6px;
        }
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 100%;
      }
    }
  }
}

/* Card Styles */
.spacecard {
  padding: 32px 16px 0;

  .started_card {
    background: #D9F2FF;
    &.finished {
      background: #f3f3f3;
    }
  }

  .homework_card {
    background: #FFF8DD;
  }

  .subject_time {
    display: flex;
    align-items: center;

    span {
      color: #9B9FA9;
      font-size: 14px;
      font-weight: 500;
      margin-left: 8px;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /*height: 112px;*/
    padding: 24px;
    border-radius: 16px;


    img {
      width: 64px;
      height: 64px;
      border-radius: 100px;
      margin-right: 16px;
    }

    .subject_data {
      display: flex;
      align-items: center;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }

      p.started {
        font-weight: 600;
        color: #43B4EB;
        margin-top: 9px;
      }

      p.standart {
        font-weight: 500;
        color: #9B9FA9;
      }
    }
  }
}

@media(max-width: 992px) {

  .home_wrapper {
    width: 100%;
  }

  .notification_wrapper {
    display: none;
  }

  .home__home_header {
    padding-bottom: 32px !important;
  }

  h1#page-title {
    margin-bottom: 15px !important;
  }

  .home__home_header-teacher .name h4 {
    width: auto;
  }

  .spacecard__card,
  .started_card,
  .standart_block,
  .homework_card {
    height: auto;
  }

  .control {
    margin-top: 10px;
  }

  .home__home_header {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .tabs-navigator {
    a {
      font-size: 12px !important;
    }
  }

  .spacecard__card {
    flex-direction: column;
    align-items: flex-start;
  }

  .subject_data {
    h3 {
      font-size: 14px !important;
    }
  }
}
.done-span, .reviewed-span, .closed-span {
  font-size: 14px;
  border-radius: 100px;
  padding: 3px 10px;
  display: inline-block;
}
.done-span {
  background: #F5F5F5;
  color: #7E828C;
}
.reviewed-span {
  background: #E0FBEB;
  color: #27AE60;
}
.closed-span {
  color: #B8BAC1;
  border: 1px solid #DADCE2;
}

@media(max-width: 768px) {

  .home_wrapper {
    width: 100%;
  }

  .notification_wrapper {
    display: none;
  }

  .home__home_header {
    padding-bottom: 20px !important;
  }

  .spacecard__card,
  .started_card,
  .standart_block,
  .homework_card {
    height: auto;
  }

  .control {
    margin-top: 10px;
  }

  .home__home_header {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .tabs-navigator {
    a {
      font-size: 12px !important;
    }
  }

  .home__home_header-teacher {
    flex-direction: column;

    .letter-icon-wrapper {
      display: none;
    }
  }

  .spacecard__card {
    flex-direction: column;
    align-items: flex-start;
  }

  h1#page-title {
    margin-bottom: 15px !important;
  }

  .subject_data {
    h3 {
      font-size: 14px !important;
    }
  }
}


</style>

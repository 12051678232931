<template>
  <div class="mt-3 w-100 alert alert-warning text-center">
    <div class="d-inline-block">
      <b-row class="align-items-center">
        <b-col>
          <div class="pr-2">
            {{ $t('before_class_start_check_settings') }}
          </div>
        </b-col>
        <b-col cols="auto">
          <button class="test-btn btn" @click="$bvModal.show('testinternetmodal')"><ClockSvg class="mr-2"/>{{ $t('start_test') }}</button>
        </b-col>
      </b-row>
    </div>
    <InternetAndDevicesTestModal/>
  </div>
</template>

<script>

import ClockSvg from "@/assets/svg/clock2.svg"
import InternetAndDevicesTestModal from "@/views/parts/class/InternetAndDevicesTestModal"

export default {
  name: "LinkToSettings",
  components: {
    ClockSvg,
    InternetAndDevicesTestModal
  },
}
</script>

<style scoped lang="scss">
.test-btn {
  border: 1px solid #E5B22D;
  border-radius: 16px;
  color: #E5B22D;
  padding: 11px 24px;
}
</style>
